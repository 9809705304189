import { Spin } from "antd";
import React, { FC } from "react";

interface loaderProps {
  tip?: string;
}

const Loader: FC<loaderProps> = ({ tip = "Loader..." }) => {
  return (
    <div className="min-h-[70vh] flex items-center justify-center">
      <Spin tip={tip} />
    </div>
  );
};

export default Loader;
