import React from "react";
import { LogoIcon,SarovarLogo } from "../../../res";


const ScreenLayout = ({
  children = <></>,
  title = <></>,
}: {
  children?: JSX.Element;
  title?: JSX.Element;
}) => {
  return (
    <main>
      <header className="bg-[#CFE7EA] px-6 py-2 border-b-2 border-[#00796B] border-dotted">
        <div className="w-full flex items-center justify-center">
          <img src={LogoIcon} className="max-w-[50px]" alt="logo" />
          <img src={SarovarLogo} className="max-w-[50px]" alt="logo" />
        </div>
        <div className="py-4 pb-6">{title}</div>
      </header>
      <div className="pb-24">{children}</div>
    </main>
  );
};

export default ScreenLayout;
