import { Form, Input, Select } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserType } from "../../../common/types";
import routes from "../../common/routes";
import { useBaseStore } from "../../common/stores/Base";

const Details = ({
  footer,
  onFinish,
  user,
}: {
  footer: JSX.Element;
  onFinish: () => any;
  user: UserType | null;
}) => {
  const [, { verify }] = useBaseStore();
  const navigate = useNavigate();

  const onVerify = async (values: any) => {
    const verifiedUser = await verify(values?.hostel, values?.messno);
    verifiedUser && onFinish();
  };

  useEffect(() => {
    if (!user?.email || !user?.name) {
      return navigate(routes.LOGIN);
    }
  }, [navigate, user?.email, user?.name]);

  return (
    <div>
      <Form
        layout="vertical"
        initialValues={{
          name: user?.name,
          email: user?.email,
          hostel: "sarovar",
        }}
        onFinish={onVerify}
        requiredMark={false}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Name is required" }]}
        >
          <Input disabled placeholder="Enter your name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Email is required" },
            { type: "email", message: "Enter a valid email" },
          ]}
        >
          <Input disabled placeholder="Enter your email" />
        </Form.Item>
        <Form.Item
          name="hostel"
          label="Hostel"
          rules={[{ required: true, message: "Hostel is required" }]}
        >
          <Select placeholder="Select hostel preference">
            {/* <Select.Option key={0} value={"swaraj"}>
              Swaraj
            </Select.Option>
            <Select.Option key={1} value={"sahara"}>
              Sahara
            </Select.Option>
            <Select.Option key={1} value={"sahara-ramadan"}>
              Sahara - Ramadan
            </Select.Option> */}
            <Select.Option key={2} value={"sarovar"}>
              Sarovar
            </Select.Option>
            
          </Select>
        </Form.Item>
        <Form.Item
          name="messno"
          label="Mess No."
          rules={[{ required: true, message: "Mess No. is required" }]}
        >
          <Input placeholder="Enter your Mess No." />
        </Form.Item>
        <Form.Item>{footer}</Form.Item>
      </Form>
    </div>
  );
};

export default Details;
