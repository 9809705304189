import { createStore, createHook, defaults } from "react-sweet-state";
import { config } from "../../../../common";
import actions from "./actions";
import initialState from "./initialState";

defaults.devtools = true;

const Store = createStore({
  name: `Base_MessedUp_${config.ENV}`,
  initialState,
  actions,
});

export const useBaseStore = createHook(Store, { selector: (state) => state });
