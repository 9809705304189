import { foodMenuType } from "../../types";
import { billType, noticeType } from "./types";

const initialState: {
  notices: noticeType[];
  foodmenu?: foodMenuType;
  loading: boolean;
  bill: billType | null;
  messCuts: number;
  haveCutToday: string;
  addingFeedback: boolean;
} = {
  foodmenu: {},
  loading: true,
  notices: [],
  bill: null,
  messCuts: 0,
  haveCutToday: "",
  addingFeedback: false,
};

export default initialState;
