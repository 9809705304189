import { message, Modal } from "antd";
import moment from "moment";
import { StoreActionApi } from "react-sweet-state";
import { logger } from "../../../../common";
import { MessCutType, UserType } from "../../../../common/types";
import { Cache } from "../../../../infrastructure/common";
import {
  addMessCut,
  addMessCutAdmin,
  listMessCut,
  listMessCutAdmin,
} from "../../../../infrastructure/MessCut";
import {
  getDaysDifference,
  getNoOfEffectiveMessCuts,
  isSameMonth,
  NextMonth,
  PrevMonth,
  timestamp,
} from "../../utils";
import {
  indexOfMesscut,
  overFlowErrorMessage,
  splitFullRangeIntoMonthRange,
  underFlowErrorMessage,
} from "./helpers";
import initialState from "./initialState";

const actions = {
  handleListMessCut:
    (user: UserType | null) =>
    async ({ getState, setState }: StoreActionApi<typeof initialState>) => {
      const { messcuts } = getState();
      if (messcuts.length < 1) {
        setState({ loading: true });
        const thisMonth = moment();
        const nextMonth = NextMonth;
        const prevMonth = PrevMonth;
        let allMessCuts: MessCutType[] = [];
        const listAndFilterMesscuts = async (
          month: moment.Moment
        ): Promise<MessCutType[]> => {
          let monthCuts: MessCutType[] = [];
          try {
            const snapshot = await listMessCut(
              Cache.getItem("hostel"),
              Cache.getItem("messno"),
              "y" + month.format("YY"),
              "m" + month.format("MM")
            );

            if (snapshot.exists())
              monthCuts = snapshot
                .val()
                ?.filter((val: MessCutType) =>
                  isSameMonth(val?.from, timestamp(month))
                );
            else logger.error(`${month.format("MMM")} empty`, "listMessCut()");
          } catch (error) {
            logger.error(`${month.format("MMM")} error`, "listMessCut()");
          }
          return monthCuts;
        };

        const promises: Promise<MessCutType[]>[] = [];
        [thisMonth, nextMonth, prevMonth].map((month) =>
          promises.push(listAndFilterMesscuts(month))
        );

        const snapshots = await Promise.all(promises);
        snapshots.map(
          (snapshot) => (allMessCuts = [...allMessCuts, ...snapshot])
        );

        setState({ messcuts: allMessCuts });
        setState({ loading: false });
      }
    },

  handleAddSingleMessCut:
    (cutRange: number[]) =>
    async ({ setState, getState }: StoreActionApi<typeof initialState>) => {
      const startStamp = moment(cutRange[0]),
        endStamp = moment(cutRange[1]),
        hostel = Cache.getItem("hostel"),
        messno = Cache.getItem("messno");

      try {
        const userSnapshot = await listMessCut(
          hostel,
          messno,
          "y" + startStamp.format("YY"),
          "m" + startStamp.format("MM")
        );
        const prevMesscuts = userSnapshot.exists()
          ? userSnapshot.val() ?? []
          : [];
        const startDay = parseInt(startStamp.format("DD")),
          endDay = parseInt(endStamp.format("DD"));

        // check if current mess cuts includes dates with previous mess cuts
        // Scenario: 1-6 => new mess cut, 3-5 => old mess cut
        const prevCutInclusive = prevMesscuts
          ?.map(
            (cut: MessCutType) =>
              moment(cut?.from).isBetween(
                moment(cutRange[0]),
                moment(cutRange[1])
              ) ||
              moment(cut?.to).isBetween(
                moment(cutRange[0]),
                moment(cutRange[1])
              )
          )
          ?.some((val: boolean) => val === true);
        if (prevCutInclusive)
          return message.warn("Dates include previous mess cuts.");
        // return error if the new mess cuts date is already included in previous mess cuts

        // Total no of messcuts, in this messcut request
        const totalNoOfDays = endDay - startDay,
          // Effective no of messcuts, in this messcut request
          effNoOfDays = getNoOfEffectiveMessCuts(totalNoOfDays),
          // Total messcuts till now, this month
          totalMessCuts = prevMesscuts
            .filter((val: MessCutType) => isSameMonth(val?.from, cutRange[0]))
            .reduce(
              (acc: number, val: MessCutType) =>
                acc + getDaysDifference(val?.from, val?.to),
              effNoOfDays
            );

        if (totalNoOfDays < 2) {
          console.log(totalNoOfDays, effNoOfDays, totalMessCuts, prevMesscuts, cutRange[0])

          underFlowErrorMessage(startStamp, totalNoOfDays);
        } else if (totalMessCuts >= 30) {
          overFlowErrorMessage(startStamp, prevMesscuts);
        } else {
          // START: 1 < noOfCuts < 30

          const userCutData = {
              from: cutRange[0],
              to: cutRange[1],
              isValid: true,
              messno,
            },
            adminCutData = {
              isValid: true,
              messno,
            };

          // START: adding messcuts for users
          await addMessCut(
            hostel,
            messno,
            "y" + startStamp.format("YY"),
            "m" + startStamp.format("MM"),
            [userCutData, ...prevMesscuts]
          );
          // END: adding messcuts for users

          // START: Getting old messcuts for admins
          let adminSnapshot = await listMessCutAdmin(
            hostel,
            "y" + startStamp.format("YY"),
            "m" + startStamp.format("MM")
          );
          let adminData = adminSnapshot.val() ?? {};
          // END: Getting old messcuts for admins

          // START: Adding new messcuts to old messcuts for admins
          Array(endDay - startDay + 1)
            .fill(0)
            .map(
              (_, i) =>
                // 0,1,2 to d01,d02,d03
                `d${
                  startDay + i > 9
                    ? startDay + i
                    : "0" + (startDay + i).toString()
                }`
            )
            .forEach((date) => {
              adminData = {
                ...adminData,
                [date]: {
                  ...adminData?.[date],
                  [messno]: adminCutData,
                },
              };
            });
          // END: Adding new messcuts to old messcuts for admins

          // START: Saving new messcuts for admins
          await addMessCutAdmin(
            hostel,
            "y" + startStamp.format("YY"),
            "m" + startStamp.format("MM"),
            adminData
          );
          // END: Saving new messcuts for admins

          // START: Updating localstate
          const { messcuts } = getState();
          setState({ messcuts: [userCutData, ...messcuts] });
          message.success(
            `Successfully added ${startStamp.format("MMMM")} messcuts`
          );
          // END: Updating localstate
        }
      } catch (error) {
        logger.error(error, "handleAddSingleMessCut()");
        message.error(`Error in adding ${startStamp.format("MMMM")} messcuts`);
      }
    },

  handleAddMessCuts:
    (
      cutRangeValue: number[] | null[],
      setCutRange: React.Dispatch<React.SetStateAction<number[] | null[]>>,
      onSuccess: () => void
    ) =>
    async ({ dispatch, setState }: StoreActionApi<typeof initialState>) => {
      setState({ adding: true });
      let cutRange = [0, 0];

      if (cutRangeValue[0] !== null) cutRange[0] = cutRangeValue[0];
      if (cutRangeValue[1] !== null) cutRange[1] = cutRangeValue[1];

      try {
        const startStamp = moment(cutRange[0]),
          endStamp = moment(cutRange[1]);
        const startMonth = parseInt(startStamp.format("MM")),
          endMonth = parseInt(endStamp.format("MM"));

        if (cutRange[0] === 0) message.error("Please select a start date!");
        else if (cutRange[1] === 0) message.error("Please select an end date!");
        else if (startMonth === endMonth) {
          await dispatch(actions.handleAddSingleMessCut(cutRange));
          setState({ messcuts: [], loading: true });
          onSuccess();
        } else {
          // [25/10/2022 to 10/11/2022]
          // as
          // [[25/10/2022 to 31/10/2022], [01/11/2022 to 10/11/2022]]
          // (timestamps)
          const cutRanges: number[][] = splitFullRangeIntoMonthRange(
            cutRange,
            startStamp,
            endStamp
          );
          const promises: Promise<void>[] = cutRanges.map((range) =>
            dispatch(actions.handleAddSingleMessCut(range))
          );
          await Promise.all(promises);
          setState({ messcuts: [], loading: true });
          onSuccess();
        }
      } catch (error) {
        logger.error(error, "handleAddMessCuts()");
      } finally {
        setCutRange([null, null]);
        setState({ adding: false });
      }
    },

  handleDeleteMessCut:
    (user: UserType | null, cut: MessCutType) =>
    async ({
      getState,
      setState,
      dispatch,
    }: StoreActionApi<typeof initialState>) => {
      const startDate = moment(cut?.from);
      const endDate = moment(cut?.to);
      const { messcuts } = getState();
      const hostel = Cache.getItem("hostel"),
        messno = Cache.getItem("messno");

      Modal.confirm({
        centered: true,
        title: `Are you sure you want to delete?`,
        content: `Confirm the deletion of messcut from ${startDate.format(
          "DD MMM YYYY"
        )} to ${endDate.format("DD MMM YYYY")}`,
        onOk: async () => {
          try {
            const snapshot = await listMessCut(
              hostel,
              messno,
              "y" + startDate.format("YY"),
              "m" + startDate.format("MM")
            );
            if (snapshot.exists()) {
              const dbCuts: MessCutType[] = snapshot.val();

              // START: Deleting from user data

              let index = indexOfMesscut(dbCuts, cut);
              if (index >= -1) {
                let newCuts = dbCuts.filter((_, j) => j !== index);
                await addMessCut(
                  hostel,
                  messno,
                  "y" + startDate.format("YY"),
                  "m" + startDate.format("MM"),
                  newCuts
                );
              }
              // END: Deleting from user data

              // START: Deleting from admin data
              let adminSnapshot = await listMessCutAdmin(
                hostel,
                "y" + startDate.format("YY"),
                "m" + startDate.format("MM")
              );
              if (adminSnapshot.exists()) {
              }
              let adminData = adminSnapshot.val() ?? {};
              Object.keys(adminData).forEach((currDay) /* d01, d02 */ => {
                Object.keys(adminData[currDay]).forEach(
                  (currMessno) /* mn01, mn02 */ => {
                    if (currMessno === Cache.getItem("messno")) {
                      let currDate = parseInt(currDay.replace("d", ""));
                      if (
                        parseInt(startDate.format("DD")) <= currDate &&
                        currDate <= parseInt(endDate.format("DD"))
                      ) {
                        adminData[currDay][currMessno] = null;
                      }
                    }
                  }
                );
              });
              await addMessCutAdmin(
                hostel,
                "y" + startDate.format("YY"),
                "m" + startDate.format("MM"),
                adminData
              );
              // END: Deleting from admin data

              // START: Deleting from local data
              index = messcuts.indexOf(cut);
              if (index >= -1) {
                let newCuts = messcuts.filter((_, j) => j !== index);
                setState({ messcuts: newCuts });
              }
              // END: Deleting from local data

              message.success("MessCut deleted!");
            } else {
              message.warning("MessCut not found!");
            }
          } catch (error) {
            logger.error(error, "handleDeleteMessCut()");
            message.error("Error in deleting MessCut!");
          }
        },
        okText: "Delete",
      });
    },
};

export default actions;
