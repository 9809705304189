import { Spin } from "antd";
import { AiOutlineCloudUpload } from "react-icons/ai";

export const uploadButton = (loading: boolean) => {
  return loading ? (
    <Spin />
  ) : (
    <AiOutlineCloudUpload size={30} color="#00796B" />
  );
};
