import React from "react";
import { BiCut } from "react-icons/bi";
import { useHomeStore } from "../../common/stores/Home";

const HaveCut = () => {
  const [{ haveCutToday }] = useHomeStore();

  return (
    <>
      {haveCutToday !== "" ? (
        <div className="min-w-[4/5] flex items-center m-2 px-4 py-2 rounded-xl border-red-500 border-[1px] bg-red-50">
          <BiCut className="text-red-600 text-2xl mr-4" />
          You have a mess cut today!
        </div>
      ) : (
        <div className="min-w-[4/5] flex items-center m-2 px-4 py-2 rounded-xl border-green-500 border-[1px] bg-green-50">
          <BiCut className="text-green-600 text-2xl mr-4" />
          You have&nbsp;<b>no</b> &nbsp;mess cuts today!
        </div>
      )}
    </>
  );
};

export default HaveCut;
