import { message } from "antd";
import { StoreActionApi } from "react-sweet-state";
import { logger } from "../../../../common";
import { UserType } from "../../../../common/types";
import {
  getPreRegisteredUser,
  getUser,
  getUsers,
  saveUserInfo,
  saveUserInMess,
} from "../../../../infrastructure/Auth";
import { Cache } from "../../../../infrastructure/common";
import {
  Auth,
  RecaptchaVerifier,
  signInWithGoogle,
  signInWithPhone,
  signOut,
} from "../../../../infrastructure/common/firebase";
import routes from "../../routes";
import initialState from "./initialState";

const actions = {
  setLoading:
    (loading: boolean) =>
    ({ setState }: StoreActionApi<typeof initialState>) => {
      setState({ loading });
    },

  setUser:
    (user: UserType) =>
    ({ setState }: StoreActionApi<typeof initialState>) => {
      setState({ user });
    },

  updateUser:
    (key: any, value: any) =>
    ({ setState, getState }: StoreActionApi<typeof initialState>) => {
      let { user } = getState();
      let newUser: any = user;
      newUser[key] = value;
      setState({ user: JSON.parse(JSON.stringify(newUser)) });
    },

  logout:
    (navigate: any) =>
    async ({ dispatch }: StoreActionApi<typeof initialState>) => {
      try {
        dispatch(actions.setLoading(true));
        await signOut();
        Cache.clear();
        navigate(routes.LOGIN);
        dispatch(actions.setLoading(false));
      } catch (error) {
        logger.error(error, "logout");
      }
    },

  login:
    (navigate: any) =>
    async ({ setState, dispatch }: StoreActionApi<typeof initialState>) => {
      dispatch(actions.setLoading(true));
      try {
        const response = await signInWithGoogle();
        const { user } = response;
        const users = await getUsers();
        const newUser: UserType = {
          name: user?.displayName ?? "",
          email: user?.email ?? "",
          mobile: user?.phoneNumber ?? "",
          photoURL: user?.photoURL ?? "",
          uid: user?.uid,
          active: false,
        };
        if (users?.exists()) {
          const data = users.val();
          const existingUsers = Object.keys(data);
          const existingUser = existingUsers?.includes(user?.uid)
            ? true
            : false;

          if (!existingUser) {
            await saveUserInfo(newUser);
            setState({ user: newUser, activeUser: false });
            navigate(routes.AUTH);
          } else {
            const userDetails = await getUser(user?.uid);
            if (userDetails.exists()) {
              const userData: UserType = userDetails?.val();
              setState({
                user: userData,
                activeUser: userData?.active ?? false,
              });
              if (userData?.active) {
                Cache.setItem(userData);
                navigate(routes.HOME);
              } else {
                navigate(routes.AUTH);
              }
            }
          }
        } else {
          await saveUserInfo(newUser);
          setState({ user: newUser, activeUser: false });
          navigate(routes.AUTH);
        }
      } catch (error: any) {
        logger.error(error, "login");
      }
    },

  verify:
    (hostel: string, messno: string) =>
    async ({
      getState,
      setState,
      dispatch,
    }: StoreActionApi<typeof initialState>) => {
      setState({ buttonLoading: true });
      try {
        messno = "mn" + messno;
        const { user } = getState();
        const preRegisteredUser = await getPreRegisteredUser(hostel, messno);
        if (!preRegisteredUser?.exists()) {
          message.error("User does not exist.");
          return false;
        } else {
          const newUser = preRegisteredUser?.val();
          setState({ user: { ...user, ...newUser, hostel, messno } });
          await saveUserInfo({ ...user, active: false, hostel, messno });
          window.recaptchaVerifier = new RecaptchaVerifier(
            "recaptcha-container",
            {
              size: "invisible",
              callback: (response: any) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                dispatch(actions.signInWithOtp());
              },
            },
            Auth
          );
          await dispatch(actions.signInWithOtp());
          return true;
        }
      } catch (error: any) {
        logger.error(error, "verify");
        return false;
      } finally {
        setState({ buttonLoading: false });
      }
    },

  signInWithOtp:
    () =>
    async ({ getState }: StoreActionApi<typeof initialState>) => {
      try {
        const { user } = getState();
        const response = await signInWithPhone(
          `+91${user?.mobile}`,
          window.recaptchaVerifier
        );
        window.confirmationResult = response;
        return true;
      } catch (error: any) {
        logger.error(error, "signInWithOtp");
        return false;
      }
    },

  verifyOtp:
    (code: number) =>
    async ({ getState, setState }: StoreActionApi<typeof initialState>) => {
      setState({ buttonLoading: true });
      try {
        const { user } = getState();
        await window.confirmationResult.confirm(code);
        const promises = [
          saveUserInfo({ ...user, active: true }),
          saveUserInMess(
            { ...user, active: true },
            user?.hostel ?? "fallback",
            user?.messno ?? "fallbackMn"
          ),
        ];
        await Promise.all(promises);
        Cache.setItem(user);
        return true;
      } catch (error: any) {
        message.error("Invalid code.");
        logger.error(error, "verifyOtp");
        return false;
      } finally {
        setState({ buttonLoading: false });
      }
    },

  getUserDetails:
    (hostel: string, messNo: string) =>
    async ({ setState }: StoreActionApi<typeof initialState>) => {
      try {
        const user = await getPreRegisteredUser(hostel, messNo);
        if (!user.exists()) return false;
        setState({ user: user.val() });
        Cache.setItem(user.val());
        return true;
      } catch (error: any) {
        logger.error(error, "getUserDetails");
      }
    },
};

export default actions;
