import { Modal } from "antd";
import moment, { Moment } from "moment";
import { MessCutType } from "../../../../common/types";
import { getDatesInBetween, timestamp } from "../../utils";

export const splitFullRangeIntoMonthRange = (
  cutRange: number[],
  startStamp: Moment,
  endStamp: Moment
): number[][] => {
  const endMonth = parseInt(endStamp.format("MM"));
  const cutRanges: number[][] = [];
  const monthDiff = moment(
    `01 ${endStamp.format("MM YYYY")}`,
    "DD MM YYYY"
  ).diff(moment(`01 ${startStamp.format("MM YYYY")}`, "DD MM YYYY"), "months");
  let currStartStamp = moment(cutRange[0]),
    currEndStamp = moment(cutRange[0]),
    currEndDate = currStartStamp.daysInMonth().toString();

  Array(monthDiff + 1)
    .fill(0)
    .forEach((_) => {
      if (parseInt(currStartStamp.format("MM")) === endMonth)
        currEndDate = endStamp.format("DD");
      else currEndDate = currStartStamp.daysInMonth().toString();
      currEndStamp = moment(
        `${currEndDate} ${currStartStamp.format("MM YYYY")}`,
        "DD MM YYYY"
      );
      cutRanges.push([timestamp(currStartStamp), timestamp(currEndStamp)]);
      currStartStamp = moment(
        `01 ${currStartStamp.add(1, "months").format("MM YYYY")}`,
        "DD MM YYYY"
      );
    });

  return cutRanges;
};

export const overFlowErrorMessage = (startStamp: Moment, prevMesscuts: any) =>
  Modal.error({
    centered: true,
    title: "Maximum 10 days!",
    content: `You have reached the maximum limit of 10 days in ${startStamp.format(
      "MMMM"
    )}. The messcut days in ${startStamp.format("MMMM")} are \n${prevMesscuts
      ?.map((currVal: MessCutType) =>
        getDatesInBetween(currVal?.from, currVal?.to).map(
          (dt) => `${dt} ${moment(currVal?.from).format("MMM")}`
        )
      )
      ?.join(", ")}`, // Display messcut dates as 02 Dec, 03 Dec, 10 Dec, 11 Dec
  });

export const underFlowErrorMessage = (
  startStamp: Moment,
  totalNoOfDays: number
) =>
  Modal.error({
    centered: true,
    title: "Minimum 3 days!",
    content: `You have selected only ${totalNoOfDays} day in ${startStamp.format(
      "MMMM"
    )}. Please select atlest 3 days to add messcut.`,
  });

export const indexOfMesscut = (cuts: MessCutType[], cut: MessCutType) => {
  let index = -1;
  cuts.forEach((currCut, i) => {
    if (currCut?.from === cut?.from && currCut?.to === cut?.to) index = i;
  });
  return index;
};
