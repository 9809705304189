import "./index.css";
import { Alert, Button, Upload, UploadProps } from "antd";
import moment from "moment";
import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { useBaseStore } from "../../common/stores/Base";
import { useHomeStore } from "../../common/stores/Home";
import { useProfileStore } from "../../common/stores/Profile";
import ScreenLayout from "../../common/UI/ScreenLayout";
import HaveCut from "../Home/HaveCut";
import IsSettled from "../Home/IsSettled";
import { uploadButton } from "./helper";
import MessRules from "../Home/Rules";

const Profile = () => {
  const [
    { loading, previewUrl, uploadingImage, uploadable },
    { setImage, handleBeforeUploadFile, handleRemoveImage, uploadImage },
  ] = useProfileStore();
  const [{ user }, { setUser }] = useBaseStore();
  const [{ haveCutToday }] = useHomeStore();

  const uploadProps: UploadProps = {
    onRemove: handleRemoveImage,
    name: "avatar",
    listType: "picture-card",
    showUploadList: false,
    beforeUpload: handleBeforeUploadFile,
    onChange: setImage,
    maxCount: 1,
  };

  const DetailsItem = ({ value, title }: any) => {
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <h2 className="m-0 font-medium text-xl text-[#00796B]">{value}</h2>
        <h3 className="m-0 font-light text-base text-[#404040]">{title}</h3>
      </div>
    );
  };

  return (
    <ScreenLayout
      title={
        <h1 className="text-center text-2xl uppercase font-semibold pt-2 mb-1">
          Profile
        </h1>
      }
    >
      <div className="w-full p-6">
        {!user?.imageUploaded ? (
          <>
            <Alert
              message="Message from Admin"
              description="Profile image is mandatory to generate QR Code. Without QR Code access to mess will be denied. Once an image is uploaded, there won't be an option to change the image."
              type="info"
              showIcon
            />
            <div className="py-6">
              <h1 className="text-center my-4">Upload your profile photo</h1>
              <div className="w-full flex items-center justify-center">
                <Upload {...uploadProps} className="avatar-uploader !w-fit">
                  {previewUrl ? (
                    <img
                      src={previewUrl}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton(uploadingImage)
                  )}
                </Upload>
              </div>
              <div className="w-full flex items-center justify-center">
                <Button
                  size="large"
                  className="w-2/4 !rounded-lg mt-4 !bg-[#00796B] !text-white text !border-transparent hover:!border-[#00ad99ab]"
                  loading={loading}
                  onClick={() => uploadImage(user, setUser)}
                  disabled={uploadable === null}
                >
                  Upload Image
                </Button>
              </div>
            </div>
          </>
        ) : (
          <div className="flex w-full flex-col items-center justify-center">
            <img
              className="rounded-full w-1/3 mb-4 "
              src={user?.photoURL}
              alt="profile"
            />

            <h1 className="barrierName text-[#00796B] text-center text-3xl uppercase font-semibold px-6">
              {" "}
              {user?.name}
            </h1>

            <div className="flex items-center justify-around w-full ">
              <DetailsItem
                title={"Mess No."}
                value={user?.messno?.replace("mn", "")}
              />
              <div className="bg-[#c9c9c9] h-[50px] w-[1px]"></div>
              <DetailsItem
                title={"Today's Date"}
                value={moment(Date.now()).format("DD MMM YY")}
              />
            </div>
            <div className="bg-[#c9c9c9] h-[1px] w-full my-4"></div>

            {!haveCutToday && (
              <div className="w-full mt-4 flex items-center justify-center flex-col">
                <QRCodeSVG
                  style={{ height: "150px", width: "150px", marginBottom: 20 }}
                  value={`${user?.messno}`}
                />
              </div>
            )}
            <HaveCut />
            <IsSettled />
            <MessRules />
          </div>
        )}
      </div>
    </ScreenLayout>
  );
};

export default Profile;
