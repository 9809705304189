import routes from "../../routes";
import { BiHome, BiEditAlt } from "react-icons/bi";
import { FiSettings, FiUser } from "react-icons/fi";
import { messCutsDisabledHostels } from "../../constants";
import { Cache } from "../../../../infrastructure/common";

export interface NavLinksType {
  route: string;
  icon: JSX.Element;
  title: string;
  id: string;
  disabled: boolean;
}

const NavLinks: NavLinksType[] = [
  {
    route: routes.HOME,
    icon: <BiHome />,
    title: "Home",
    id: routes.HOME,
    disabled: false,
  },
  {
    route: routes.MESS_CUT,
    icon: <BiEditAlt />,
    title: "Mess Cuts",
    id: routes.MESS_CUT,
    disabled: messCutsDisabledHostels.includes(Cache.getItem("hostel")),
  },
  {
    route: routes.PROFILE,
    icon: <FiUser />,
    title: "Profile",
    id: routes.PROFILE,
    disabled: false,
  },

  {
    route: routes.SETTINGS,
    icon: <FiSettings />,
    title: "Settings",
    id: routes.SETTINGS,
    disabled: false,
  },
];

export default NavLinks;
