import { Form, Input } from "antd";
import React from "react";
import { useBaseStore } from "../../common/stores/Base";

const Otp = ({
  footer,
  onFinish,
}: {
  footer: JSX.Element;
  onFinish: () => any;
}) => {
  const [{ user }, { verifyOtp }] = useBaseStore();

  const onOtpSubmit = async (values: any) => {
    const isVerified = await verifyOtp(values?.otp);
    isVerified && onFinish();
  };

  return (
    <div>
      <Form onFinish={onOtpSubmit} layout="vertical" requiredMark={false}>
        <h3>
          Mobile Number:{" "}
          {`${user?.mobile?.slice(0, 2)}***${user?.mobile?.slice(-3)}`}
        </h3>
        <Form.Item
          name="otp"
          label="OTP"
          rules={[{ required: true, message: "OTP is required" }]}
        >
          <Input
            maxLength={6}
            style={{ width: "100%" }}
            placeholder="Enter OTP"
          />
        </Form.Item>
        <Form.Item>{footer}</Form.Item>
      </Form>
    </div>
  );
};

export default Otp;
