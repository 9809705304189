const routes = {
  HOME: `/`,
  LOGIN: `/login`,
  AUTH: `/auth`,
  MESS_CUT: `/mess-cut`,
  SETTINGS: "/settings",
  PROFILE: "/profile",
} as const;

export default routes;
