import React from "react";
import { useHomeStore } from "../../common/stores/Home";

const MessMenu = () => {
  const MessMenuItem = ({ title, item,price }: any) => {
    return (
      <div className="w-full m-auto my-5 rounded-lg border-[1px] border-[#00828e30]">
        <p className="m-0 px-4 py-2 bg-[#00828e30] text-[#00796B] font-semibold">
          {title}
        </p>
        <p className="m-0 px-4 py-2 text-[#575757]">{item}</p>
        <p className="m-0 px-4 py-2 text-[#00796B]">Price for Guest: ₹{price}</p>
        
      </div>
    );
  };

  const [{ foodmenu }] = useHomeStore();

  return (
    <div className="w-full p-6">
      <h2 className="uppercase font-semibold text-xl text-[#00796B]">Menu</h2>
      <MessMenuItem
        title="Breakfast"
        item={foodmenu?.breakfast?.dish?? "Not Available"}
        price ={foodmenu?.breakfast?.price?? "Not Available"}
      />
      <MessMenuItem title="Lunch" item={foodmenu?.lunch?.dish?? "Not Available"} price ={foodmenu?.lunch?.price?? "Not Available"} />
      <MessMenuItem title="Dinner" item={foodmenu?.dinner?.dish?? "Not Available"} price ={foodmenu?.dinner?.price?? "Not Available"} />
    </div>
  );
};

export default MessMenu;
