import { RcFile } from "antd/lib/upload";
import moment from "moment";
import Resizer from "react-image-file-resizer";

export const timestamp = (momObject: moment.Moment): number =>
  momObject.toDate().getTime();

export const isSameMonth = (stamp1: number, stamp2: number = Date.now()) =>
  parseInt(moment(stamp1).format("MM")) ===
  parseInt(moment(stamp2).format("MM"));

export const PrevMonth = moment(
  `01 ${moment().format("MM YYYY")}`,
  "DD MM YYYY"
).subtract(1, "month");

export const NextMonth = moment(
  `01 ${moment().format("MM YYYY")}`,
  "DD MM YYYY"
).add(1, "month");

export const isPrevMonth = (stamp: number) => {
  return moment(stamp).format("MM") === PrevMonth.format("MM");
};

export const isNextMonth = (stamp: number) => {
  return moment(stamp).format("MM") === NextMonth.format("MM");
};

export const getDatesInBetween = (from: number, to: number): number[] => {
  const fromMom = moment(from),
    toMom = moment(to);
  return Array(
    parseInt(toMom.format("DD")) - parseInt(fromMom.format("DD")) + 1
  )
    .fill(0)
    .map((_, i) => parseInt(fromMom.format("DD")) + i);
};

export const getDaysDifference = (from: number, to: number) => {
  const fromStamp = timestamp(
    moment(
      `${moment(from).format("DD MM YYYY")} 12:00:00`,
      "DD MM YYYY HH:mm:ss"
    )
  );
  const toStamp = timestamp(
    moment(`${moment(to).format("DD MM YYYY")} 12:00:00`, "DD MM YYYY HH:mm:ss")
  );
  return moment(toStamp).diff(moment(fromStamp), "days") + 1;
};

export const getMonthsDifference = (from: number, to: number) =>
  moment(to).diff(moment(from), "months") + 1;

export const getNoOfEffectiveMessCuts = (days: number): number => {
  // updated the effective mess cut days as per the new rule for sarovar
  switch (days) {
    case 1:
      return 0;
    case 2:
      return 0;
    case 3:
      return 2;
    case 4:
      return 3;
    default:
      return days;
  }
};

export const getDayOfWeek = (day: number): string => {
  switch (day) {
    case 0:
      return "sunday";
    case 1:
      return "monday";
    case 2:
      return "tuesday";
    case 3:
      return "wednesday";
    case 4:
      return "thursday";
    case 5:
      return "friday";
    case 6:
      return "saturday";
    case 7:
      return "sunday";
    default:
      return "monday";
  }
};

export const resizeFile = (file: any) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      720,
      720,
      "JPEG",
      100,
      0,
      (uri: any) => {
        resolve(uri);
      },
      "file"
    );
  });

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};
