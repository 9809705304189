import { MessCutType } from "../../../../common/types";

const initialState: {
  messcuts: MessCutType[];
  loading: boolean;
  adding: boolean;
} = {
  messcuts: [],
  loading: true,
  adding: false,
};

export default initialState;
