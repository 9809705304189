import { UserType } from "../../common/types";
import { DB } from "../common/firebase";

export const saveUserInfo = (user: UserType) =>
  DB.set(`users/${user?.uid}`, user);

export const getUsers = () => DB.get(`users`);

export const getUser = (userId: string) => DB.get(`users/${userId}`);

export const getPreRegisteredUser = (hostel: string, messNo: string) =>
  DB.get(`${hostel}/members/${messNo}`);

export const saveUserInMess = (
  user: UserType,
  hostel: string,
  messno: string
) => DB.set(`${hostel}/members/${messno}`, user);
