import React from "react";
import { BiRupee } from "react-icons/bi";
import { useHomeStore } from "../../common/stores/Home";

const IsSettled = () => {
  const [{ bill }] = useHomeStore();

  return (
    <>
      {bill === null || bill?.isSettled ? (
        <div className="min-w-[4/5] flex items-center m-2 px-4 py-2 rounded-xl border-green-500 border-[1px] bg-green-50">
          <BiRupee className="text-green-600 text-2xl mr-4" />
          You have &nbsp; <b>no</b> &nbsp; pending bills!
        </div>
      ) : (
        <div className="min-w-[4/5] flex items-center m-2 px-4 py-2 rounded-xl border-red-500 border-[1px] bg-red-50">
          <BiRupee className="text-red-600 text-2xl mr-4" />
          You have &nbsp; <b>not paid</b> &nbsp; the last bill!
        </div>
      )}
    </>
  );
};

export default IsSettled;
