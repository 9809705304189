import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "antd/dist/antd.min.css"; // antd
import "./presentation/common/assets/antd.css"; // antd override
import routes from "./presentation/common/routes";
import Home from "./presentation/pages/Home";
import Login from "./presentation/pages/Login";
import MessCut from "./presentation/pages/Cut";
import Auth from "./presentation/pages/Auth";
import Layout from "./presentation/common/UI/Layout";
import Settings from "./presentation/pages/Settings";
import Profile from "./presentation/pages/Profile";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes?.HOME} element={<Layout />}>
          <Route path={routes?.HOME} element={<Home />} />
          <Route path={routes?.SETTINGS} element={<Settings />} />
          <Route path={routes?.MESS_CUT} element={<MessCut />} />
          <Route path={routes?.PROFILE} element={<Profile />} />
        </Route>
        <Route path={routes?.LOGIN} element={<Login />} />
        <Route path={routes?.AUTH} element={<Auth />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
