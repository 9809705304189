import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, get } from "firebase/database";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  browserLocalPersistence,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  setPersistence,
  signOut as firebaseSignOut,
} from "firebase/auth";
import {
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { config } from "../../common";

const { FIREBASE_CONFIG } = config;

const App = initializeApp(FIREBASE_CONFIG);

const AuthProvider = new GoogleAuthProvider();
const Auth = getAuth();

const Analytics = getAnalytics(App);

const Database = getDatabase(App);

const signInWithGoogle = async () => {
  await setPersistence(Auth, browserLocalPersistence);
  return signInWithPopup(Auth, AuthProvider);
};

const signOut = () => {
  return firebaseSignOut(Auth);
};

const signInWithPhone = (phoneNumber: string, appVerifier: any) =>
  signInWithPhoneNumber(Auth, phoneNumber, appVerifier);

const customGet = (path: string) => get(ref(Database, path));
const customSet = (path: string, data: any) => set(ref(Database, path), data);
const DB = { get: customGet, set: customSet };

const fbStorage = getStorage();

const customUploadTask = (path: string, file: any) =>
  uploadBytesResumable(storageRef(fbStorage, path), file);

const Storage = {
  createUploadTask: customUploadTask,
  getUrl: getDownloadURL,
};

export {
  DB,
  signInWithGoogle,
  signOut,
  Auth,
  RecaptchaVerifier,
  signInWithPhone,
  Analytics,
  Storage,
};
