import { Button, Result, Steps } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../common/routes";
import { useBaseStore } from "../../common/stores/Base";
import Details from "./Details";
import Otp from "./Otp";
// import Otp from "./Otp";

const Auth = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const navigate = useNavigate();
  const [{ user, buttonLoading }] = useBaseStore();

  const Footer = () => (
    <div className="flex items-center justify-between mt-4">
      {currentStep > 0 ? (
        <Button
          onClick={() => setCurrentStep((prev) => prev - 1)}
          type="primary"
        >
          Previous
        </Button>
      ) : (
        <p></p>
      )}
      {currentStep < 3 ? (
        <Button
          onClick={() => {
            currentStep === 2 && navigate(routes.HOME);
          }}
          htmlType="submit"
          type="primary"
          id="messedup-signin"
          loading={buttonLoading}
        >
          {currentStep === 1 ? "Verify" : currentStep === 2 ? "Finish" : "Next"}
        </Button>
      ) : (
        <p></p>
      )}
    </div>
  );

  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center">
      <div className="border-[1px] border-gray-100 shadow-lg rounded-lg p-6 py-10 w-full md:w-8/12 lg:w-6/12">
        <h1 className="text-center mb-10 uppercase text-themeBlue text-xl">
          Create Account
        </h1>
        <Steps
          current={currentStep}
          onChange={(step) => setCurrentStep(step)}
          progressDot
          direction="horizontal"
        >
          <Steps.Step
            disabled={currentStep < 0}
            onStepClick={() => {}}
            title="Enter Details"
          />
          <Steps.Step disabled={currentStep < 1} title="Enter OTP" />
          <Steps.Step disabled={currentStep < 2} title="Done" />
        </Steps>
        <div className="mt-6">
          {currentStep === 0 ? (
            <Details
              footer={<Footer />}
              onFinish={() => setCurrentStep((prev) => prev + 1)}
              user={user}
            />
          ) : currentStep === 1 ? (
            <Otp
              footer={<Footer />}
              onFinish={() => setCurrentStep((prev) => prev + 1)}
            />
          ) : currentStep === 2 ? (
            <div>
              <Result status="success" title="Verification Successfull!" />
              <Footer />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div id="recaptcha-container" />
    </div>
  );
};

export default Auth;
