import React, { useEffect, useState } from "react";
import { useBaseStore } from "../../common/stores/Base";
import { useHomeStore } from "../../common/stores/Home";
import Loader from "../../common/UI/Loader";
import ScreenLayout from "../../common/UI/ScreenLayout";
import UpiModal from "../../common/UI/UpiModal";
import Details from "./Details";
import MessMenu from "./MessMenu";
import Notices from "./Notices";

const Home = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [{ user }, { updateUser }] = useBaseStore();
  const [
    { loading, bill, messCuts },
    { handleFetchHomeData, handleOptForRamadan },
  ] = useHomeStore();

  useEffect(() => {
    handleFetchHomeData(user);
  }, [handleFetchHomeData, user]);

  if (loading) return <Loader tip={"Breath in... Breath out..."} />;

  return (
    <ScreenLayout
      title={
        <div>
          <Details
            {...{
              ...user,
              ...bill,
              messcuts: messCuts,
              onPay: () => setShowModal((prev) => !prev),
              handleOptForRamadan,
              updateUser,
            }}
          />
        </div>
      }
    >
      <div>
        {/* <HaveCut /> */}
        <MessMenu />
        <Notices />
        {showModal && (
          <UpiModal
            {...{
              setShowModal,
              showModal,
              totalAmount: bill?.totalAmount ?? 0,
            }}
          />
        )}
      </div>
    </ScreenLayout>
  );
};

export default Home;
