import { Button, DatePicker, message } from "antd";
import { useNavigate } from "react-router-dom";
import type { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useMessCutStore } from "../../common/stores";
import { useBaseStore } from "../../common/stores/Base";
import Loader from "../../common/UI/Loader";
import ScreenLayout from "../../common/UI/ScreenLayout";
import {
  isNextMonth,
  isPrevMonth,
  isSameMonth,
  NextMonth,
  PrevMonth,
  timestamp,
} from "../../common/utils";
import "./index.css";
import routes from "../../common/routes";
import CutDeck from "./CutDeck";
import { Cache } from "../../../infrastructure/common";
import { messCutsDisabledHostels } from "../../common/constants";

const MessCut = () => {
  const navigate = useNavigate();
  const [cutRange, setCutRange] = useState<number[] | null[]>([null, null]);
  const [{ user }] = useBaseStore();
  const [
    { messcuts, loading, adding },
    { handleListMessCut, handleAddMessCuts },
  ] = useMessCutStore();

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    const isPastDate = current && current < moment().endOf("day");

    // Can not select previous dates if starting date is selected
    const isBeforeStartDate =
      cutRange[0] === null
        ? false
        : timestamp(current) <= cutRange[0] + 86400000;

    // Can not select days of messcuts
    const isAlreadyCut = messcuts
      ?.map((cuts) => {
        return current.local().isBetween(
          // Subtract 6 hours and add one day is to make it factor in the timezone. For
          // some reason, the date was not getting disabled on start date for some mess cuts
          moment(cuts?.from).subtract(6, "hours").local(),
          moment(cuts?.to).add(1, "day").local()
        );
      })
      .some((item) => item === true);

    return isBeforeStartDate || isPastDate || isAlreadyCut;
  };

  useEffect(() => {
    handleListMessCut(user);
  }, [handleListMessCut, user]);

  if (loading) return <Loader tip="Fetching your mess cuts..." />;

  return (
    <ScreenLayout
      title={
        <div className="">
          <DatePicker.RangePicker
            disabledDate={disabledDate}
            size="large"
            className="!rounded-lg h-[45px] w-full !mt-6"
            value={
              cutRange[0] && cutRange[1]
                ? [moment(cutRange[0]), moment(cutRange[1])]
                : [null, null]
            }
            onChange={(values) => {
              if (values) {
                const startMoment = moment(values[0]?.toDate().getTime());
                const endMoment = moment(values[1]?.toDate().getTime());
                if (
                  startMoment.format("DD MM YYYY") ===
                  endMoment.format("DD MM YYYY")
                )
                  message.error("Select atleast 2 days to add a messcut");
                else
                  setCutRange([timestamp(startMoment), timestamp(endMoment)]);
              } else setCutRange([null, null]);
            }}
          />
          <Button
            size="large"
            className="w-full !rounded-lg mt-4 !bg-[#00796B] !text-white text !border-transparent hover:!border-[#00ad99ab]"
            loading={loading || adding}
            onClick={() =>
              handleAddMessCuts(cutRange, setCutRange, () =>
                navigate(routes.HOME)
              )
            }
            disabled={messCutsDisabledHostels.includes(Cache.getItem("hostel"))}
          >
            Request
          </Button>
        </div>
      }
    >
      <div className="w-full p-6 pt-2">
        <CutDeck
          cuts={messcuts.filter((cut) => isNextMonth(cut.from))}
          month={NextMonth.format("MMMM")}
          hideIfEmpty
        />
        <CutDeck
          cuts={messcuts.filter((cut) => isSameMonth(cut.from))}
          month={moment().format("MMMM")}
        />
        <CutDeck
          cuts={messcuts.filter((cut) => isPrevMonth(cut.from))}
          month={PrevMonth.format("MMMM")}
        />
      </div>
    </ScreenLayout>
  );
};

export default MessCut;
