import { StoreActionApi } from "react-sweet-state";
import moment from "moment";
import { getDayOfWeek, getDaysDifference } from "../../utils";
import { getDaysMenu } from "../../../../infrastructure/FoodMenu";
import { message, Modal } from "antd";
import { logger } from "../../../../common";
import initialState from "./initialState";
import { addFeedback, getNotices } from "../../../../infrastructure/Notices";
import { billsType, noticeType } from "./types";
import { getBillData } from "../../../../infrastructure/Bill";
import { MessCutType, UserType } from "../../../../common/types";
import { listMessCut } from "../../../../infrastructure/MessCut";
import { Cache } from "../../../../infrastructure/common";
import { updateRamadanStatus } from "../../../../infrastructure/Home";

const actions = {
  handleFetchHomeData:
    (user: UserType | null) =>
    async ({
      setState,
      getState,
      dispatch,
    }: StoreActionApi<typeof initialState>) => {
      try {
        const Promises = [
          dispatch(actions.handleGetFoodMenu(user)),
          dispatch(actions.handleGetNotices(user)),
          dispatch(actions.handleGetBillData(user)),
          dispatch(actions.handleGetMessCuts(user)),
        ];
        await Promise.all(Promises);
      } catch (error: any) {
        logger.error(error, "fetchHomeData");
        message.warn("Could not fetch home data.");
      } finally {
        setState({ loading: false });
      }
    },

  handleGetFoodMenu:
    (user: UserType | null) =>
    async ({
      getState,
      setState,
      dispatch,
    }: StoreActionApi<typeof initialState>) => {
      try {
        const dayNumber: number = moment().day();
        const day: string = getDayOfWeek(dayNumber);
        const snap = await getDaysMenu(Cache.getItem("hostel"), day);
        if (!snap?.exists())
          logger.error("Could not fetch menu data", "handleGetFoodMenu");
        setState({ foodmenu: snap?.val() });
      } catch (error: any) {
        logger.error(error, "handleGetFoodMenu");
        message.warn(error?.toString());
      } finally {
        setState({ loading: false });
      }
    },
  handleGetNotices:
    (user: UserType | null) =>
    async ({
      getState,
      setState,
      dispatch,
    }: StoreActionApi<typeof initialState>) => {
      try {
        const notices_snap = await getNotices(Cache.getItem("hostel"));
        if (!notices_snap.exists())
          return logger.error("No notices found", "handleGetNotices");
        const notices: noticeType[] = Object.keys(notices_snap?.val()).map(
          (notice) => ({
            id: notice,
            ...notices_snap?.val()[notice],
          })
        );
        setState({ notices });
      } catch (error: any) {
        logger.error(error, "handleGetNotices");
        message.warn(error?.toString());
      } finally {
        setState({ loading: false });
      }
    },
  handleGetBillData:
    (user: UserType | null) =>
    async ({ setState }: StoreActionApi<typeof initialState>) => {
      try {
        const billingDate = moment().subtract(1, "months");
        const billingYear = billingDate.format("YY");
        const billingMonth = billingDate.format("MM");
        const messNo = Cache.getItem("messno") ?? "mn0";
        const bill_snap = await getBillData(
          Cache.getItem("hostel") ?? "fallback",
          Cache.getItem("messno") ?? "fallbackMn",
          "y" + billingYear,
          "m" + billingMonth
        );
        if (!bill_snap?.exists()) {
          logger.error("Could not fetch your bill data.", "handleGetBillData");
        }
        const bill: billsType = bill_snap?.val();
        if (bill?.isPublished)
          if (bill["bill"][messNo]) setState({ bill: bill["bill"][messNo] });
      } catch (error: any) {
        logger.error(error, "handleGetBillData");
        message.warn(error?.toString());
      }
    },
  handleGetMessCuts:
    (user: UserType | null) =>
    async ({
      getState,
      setState,
      dispatch,
    }: StoreActionApi<typeof initialState>) => {
      try {
        const { haveCutToday } = getState();
        const date = moment();
        const year = date.format("YY");
        const month = date.format("MM");
        const messCutsSnap = await listMessCut(
          Cache.getItem("hostel"),
          Cache.getItem("messno"),
          "y" + year,
          "m" + month
        );
        if (!messCutsSnap.exists())
          logger.error("No mess cuts found", "handleGetMessCuts");
        const messCutsData: MessCutType[] = messCutsSnap.val();
        const messCuts: number = messCutsData
          ?.map((messCut) => getDaysDifference(messCut?.from, messCut?.to))
          .reduce((acc, val) => acc + val, 0);
        setState({ messCuts });
        const today = parseInt(moment().format("DD"));
        messCutsData?.forEach((cut) => {
          if (haveCutToday === "") {
            if (
              parseInt(moment(cut?.from).format("DD")) <= today &&
              today <= parseInt(moment(cut?.to).format("DD"))
            ) {
              setState({
                haveCutToday: `You have messcuts from ${moment(
                  cut?.from
                ).format("DD MMM")} to ${moment(cut?.to).format("DD MMM")}`,
              });
            }
          }
        });
      } catch (error: any) {
        logger.error(error, "handleGetMessCuts");
        message.warn(error?.toString());
      }
    },

  handleAddFeedback:
    (feedback: string, callback: () => void) =>
    async ({ setState }: StoreActionApi<typeof initialState>) => {
      setState({ addingFeedback: true });
      try {
        await addFeedback(Cache.getItem("hostel"), {
          content: feedback,
          messno: Cache.getItem("messno"),
          name: Cache.getItem("name"),
          timestamp: Date.now(),
        });
        message.success("Feedback sent!");
      } catch (error) {
        logger.error(error, "@handleAddFeedback");
        message.error("Feedback sending failed!");
      } finally {
        setState({ addingFeedback: false });
        callback();
      }
    },

  handleOptForRamadan:
    (value: boolean, updateUser: any) =>
    async ({
      getState,
      setState,
      dispatch,
    }: StoreActionApi<typeof initialState>) => {
      try {
        const messNo: string = Cache.getItem("messno");

        console.log("messNo", messNo);
        if (!messNo) return message.error("Mess number not found.");

        Modal.confirm({
          title: "Ramadan Mess",
          content: `Are you sure you want to ${
            value ? "opt-in" : "opt-out"
          } for sahara ramdan mess?`,
          okText: `${value ? "Opt-in" : "Opt-out"}`,
          centered: true,
          onOk: async () => {
            await updateRamadanStatus(messNo, value);
            updateUser("ramadanOpt", value);
            message.success(
              `Member ${
                value ? "opted" : "opted out"
              } from ramadan mess successfully!`
            );
          },
        });
      } catch (error) {
        logger.error(error, "");
        message.error("Some error occured, Could not change ramadan status.");
      }
    },
};

export default actions;
