import { UploadFile } from "antd";
import { logger } from "../../common";
import { Cache } from "../common";
import { Storage } from "../common/firebase";

export const uploadStorageFile = async (
  uid: string,
  file: UploadFile,
  messNumber?: string
): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    const uploadTask = Storage.createUploadTask(
      `${Cache.getItem("hostel")}/profile-images/${messNumber}`,
      file
    );
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => logger.error(error, "uploadFile()"),
      async () => {
        const downloadUrl = await Storage.getUrl(uploadTask.snapshot.ref);
        resolve(downloadUrl);
      }
    );
  });
};
