import moment from "moment";

export const alphabeticalSort = (a: string, b: string): number =>
  String(a).localeCompare(String(b));

export const numericalSort = (a: string | number, b: string | number): number =>
  parseFloat(String(a)) - parseFloat(String(b));

export const messNoSort = (a: string, b: string): number =>
  numericalSort(String(a).replace("mn", ""), String(b).replace("mn", ""));

export const timeSort = (t1: string, t2: string): number =>
  moment(t1, "YYYY-MM-DD HH:mm:ss").valueOf() -
  moment(t2, "YYYY-MM-DD HH:mm:ss").valueOf();
