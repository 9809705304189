import { Input, message, Modal } from "antd";
import React, { useState } from "react";
import { BiPlus } from "react-icons/bi";
import { useHomeStore } from "../../common/stores/Home";

const AddFeedback = () => {
  const [feedbackModal, setfeedbackModal] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [{ addingFeedback }, { handleAddFeedback }] = useHomeStore();

  return (
    <>
      <div
        className="flex justify-center cursor-pointer"
        onClick={() => setfeedbackModal(true)}
      >
        <div className="py-2 px-4 flex items-center justify-around">
          <BiPlus className="mr-6" />
          Add Feedback
        </div>
      </div>
      {/* <Button onClick={() => setfeedbackModal(true)}>Add Feedback</Button> */}
      <Modal
        title="Add Feedback"
        visible={feedbackModal}
        onCancel={() => setfeedbackModal(false)}
        centered
        okText="Send Feedback"
        okButtonProps={{ loading: addingFeedback }}
        onOk={() =>
          feedback === ""
            ? message.error("Please add some content!")
            : handleAddFeedback(feedback, () => {
                setFeedback("");
                setfeedbackModal(false);
              })
        }
      >
        <span>Feedback</span>
        <Input.TextArea
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="Write Feedback here..."
          rows={4}
          showCount
          value={feedback}
        />
      </Modal>
    </>
  );
};

export default AddFeedback;
