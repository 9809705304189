// import { Button, Divider, Form, Input } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useBaseStore } from "../../common/stores/Base";
import Loader from "../../common/UI/Loader";
import { GoogleLogo, Logo, SarovarLogo } from "../../res";

const Login = () => {
  const navigate = useNavigate();
  const [{ loading }, { login }] = useBaseStore();

  if (loading) return <Loader tip="Authenticating..." />;

  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center bg-themeGreen">
      <div className="flex-[2] w-full flex items-center justify-center bg-white rounded-b-[80px]">
        <img src={Logo} alt="logo" className="max-w-[180px]" />
      </div>
      <div className="flex-[2] w-full flex flex-col items-center justify-center">
        <img src={SarovarLogo} alt="SarovarLogo" className="max-w-[90px] mb-4" />
        
        {/* <div className="border-[1px] border-gray-100 shadow-lg rounded-2xl p-6 py-2 w-2/4 md:w-6/12 lg:w-4/12"> */}
        {/* border-2 border-gray-300 rounded */}
        {/* <Form layout="vertical" requiredMark>
          <h1 className="text-2xl font-semibold">Login</h1>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Email is required" },
              { type: "email", message: "Enter a valid email" },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              { required: true, message: "Password is required" },
              { min: 8, message: "Password must contain min 8 characters" },
            ]}
          >
            <Input.Password size="large" />
          </Form.Item>
          <Form.Item>
            <div className="flex justify-center">
              <Button size="large" type="primary" className="w-full md:w-auto">
                Login
              </Button>
            </div>
          </Form.Item>
        </Form>
        <Divider>Or continue with</Divider> */}
        <div
          onClick={() => login(navigate)}
          className="flex items-center justify-center cursor-pointer h-[60px] w-[300px] bg-white hover:shadow-xl shadow-lg rounded-full"
        >
          <img src={GoogleLogo} alt="GoogleLogo" className="max-w-[40px]" />
          <h1 className="pl-4 m-0 uppercase text-themeGreen font-semibold text-lg">
            Sign-in with Google
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Login;
