const initialState: {
  loading: boolean;
  image: any;
  uploadable: any;
  previewUrl: any;
  uploadingImage: boolean;
} = {
  loading: false,
  image: null,
  uploadable: null,
  uploadingImage: false,
  previewUrl: null,
};

export default initialState;
