import { UserType } from "../../../../common/types";

const initialState: {
  user: UserType | null;
  activeUser: boolean;
  loading: boolean;
  buttonLoading: boolean;
} = {
  user: null,
  activeUser: false,
  loading: false,
  buttonLoading: false,
};

export default initialState;
