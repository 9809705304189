import { createStore, createHook, defaults } from "react-sweet-state";
import { config } from "../../../../common";
import actions from "./actions";
import initialState from "./initialState";

defaults.devtools = true;

const Store = createStore({
  name: `Home_MessedUp_${config.ENV}`,
  initialState,
  actions,
});

export const useHomeStore = createHook(Store, {
  selector: (state) => state,
});
