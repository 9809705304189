const development = {
  //Sarovar Dev DB
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyDfNBuqbwB7p2kXW9tvAX1o9FyEbFVBp3k",
    authDomain: "sarovar-messedup-dev-2024-25.firebaseapp.com",
    projectId: "sarovar-messedup-dev-2024-25",
    databaseURL: "https://sarovar-messedup-dev-2024-25-default-rtdb.firebaseio.com", 
    storageBucket: "sarovar-messedup-dev-2024-25.appspot.com",
    messagingSenderId: "1059187434874",
    appId: "1:1059187434874:web:15a831b9a02a3e20ae62c3",
    measurementId: "G-GG27KELNLC"
  },
};

const test = {
  ...development,
};

const production = {
  //Sarovar Prod DB
  ...development,
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyBDUGKvYO2rMcnTBcnYoYJ1eZ8n1a7QiYo",
    authDomain: "sarovar-messedup-prod-2024-25.firebaseapp.com",
    projectId: "sarovar-messedup-prod-2024-25",
    databaseURL: "https://sarovar-messedup-prod-2024-25-default-rtdb.firebaseio.com",  
    storageBucket: "sarovar-messedup-prod-2024-25.appspot.com",
    messagingSenderId: "649050374265",
    appId: "1:649050374265:web:1a0b6a910ca80aeaf094a8",
    measurementId: "G-PCSFBLDH6D"
  },
};

const configs = { development, test, production };
const currentConfig = configs[process.env.NODE_ENV];

const config = {
  ENV: process.env.NODE_ENV,
  FIREBASE_CONFIG: currentConfig.FIREBASE_CONFIG,
};

export default config;
