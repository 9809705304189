import { MessCutType } from "../../common/types";
import { DB } from "../common/firebase";

export const listMessCut = (
  hostel: string = "fallback",
  messno: string,
  yy: string,
  mm: string
) => DB.get(`${hostel}/messcuts/${yy}/${mm}/${messno}`);

export const addMessCut = (
  hostel: string = "fallback",
  messno: string,
  yy: string,
  mm: string,
  data: MessCutType[]
) => DB.set(`${hostel}/messcuts/${yy}/${mm}/${messno}`, data);

export const listMessCutAdmin = (
  hostel: string = "fallback",
  yy: string,
  mm: string
) => DB.get(`${hostel}/messcuts-admin/${yy}/${mm}`);

export const addMessCutAdmin = (
  hostel: string = "fallback",
  yy: string,
  mm: string,
  data: any
) => DB.set(`${hostel}/messcuts-admin/${yy}/${mm}`, data);
