import moment from "moment";
import React from "react";
import { useHomeStore } from "../../common/stores/Home";
import { numericalSort } from "../../common/utils/sorters";

const Notices = () => {
  const NoticeItem = ({ notice }: any) => {
    return (
      <div className="relative bg-[#d0e4ec] my-5 mt-7 px-4 py-3 rounded-lg">
        <h2 className="text-base uppercase">{notice?.title}</h2>
        <div className="absolute -top-4 -right-4 bg-white border-[2px] border-[#00828e30] w-12 h-12 rounded-full flex flex-col items-center justify-center">
          <p className="m-0 text-base font-semibold">
            {moment(+notice?.id).format("DD")}
          </p>
          <p className="m-0 -mt-1 text-xs">
            {moment(+notice?.id).format("MMM")}
          </p>
        </div>
        <p className="m-0">{notice?.content}</p>
      </div>
    );
  };

  const [{ notices }] = useHomeStore();

  return (
    <div className="w-full p-6 pt-0">
      <h2 className="uppercase font-semibold text-xl text-[#00796B]">
        Notices
      </h2>
      <div>
        {notices
          ?.sort((a, b) => -numericalSort(a.id, b.id))
          ?.map((notice: any) => (
            <NoticeItem {...{ notice }} />
          ))}
      </div>
    </div>
  );
};

export default Notices;
