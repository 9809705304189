import { Modal, Switch } from "antd";
import React from "react";
import { BiMessageRoundedDetail, BiPhone } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Cache } from "../../../infrastructure/common";
import { messCommitteInfo } from "../../common/constants";
import { useBaseStore } from "../../common/stores/Base";
import ScreenLayout from "../../common/UI/ScreenLayout";
import AddFeedback from "./AddFeedback";

const Settings = () => {
  const navigate = useNavigate();
  const [{user}, { logout }] = useBaseStore();

  const SettingsItem = ({ title, item }: any) => {
    return (
      <div className="w-full m-auto my-5 rounded-lg border-[1px] border-[#00828e30]">
        <p className="m-0 px-4 py-2 bg-[#00828e30] text-[#00796B] font-semibold">
          {title}
        </p>
        <p className="m-0 px-4 py-2 text-[#575757]">{item}</p>
      </div>
    );
  };

  return (
    <ScreenLayout
      title={
        <h1 className="text-center text-2xl uppercase font-semibold pt-2 mb-1">
          Settings
        </h1>
      }
    >
      <div className="w-full p-6">
        <SettingsItem
          title="Contact"
          item={
            <div>
      {((messCommitteInfo(Cache.getItem("hostel")) as [])).map((contact: any, index: any) => (
        <div key={index}>
          <div className="flex items-center mt-2 justify-between">
            <div>
              <h4 className="m-0">{contact.name}</h4>
              <p className="m-0 text-gray-600">{contact.role}</p>
            </div>
            <p className="m-0">+91 {contact.contact}</p>
          </div>
          
          <div className="flex border-t-[1px] border-b-[1px] border-gray-300 mt-3 pb-3">
            <a
              href={`tel:+91${contact.contact}`}
              className="text-blue-600 hover:text-blue-600 pt-3 pb-1 h-full text-xl flex-1 flex justify-center"
            >
              <BiPhone />
            </a>
            <div className="h-[22px] w-[1px] bg-gray-300 mt-[10px] pb-1"></div>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`https://wa.me/91${contact.contact}`}
              className="text-green-600 hover:text-green-600 pt-3 pb-1 text-xl flex-1 flex justify-center"
            >
              <BiMessageRoundedDetail />
            </a>
          </div>
        </div>
      ))}
    </div>


          }
          />
        
        <SettingsItem
          title="Food Preference"
          item={
            <div className="flex my-2 items-center justify-center">
              <Switch
                disabled
                checked={user?.pref==="nonveg" ? true : false}
                checkedChildren="Non-Veg"
                unCheckedChildren="Veg"
                defaultChecked
                className="!bg-themeGreen !w-24"
              />
            </div>
          }
        />
        <SettingsItem title="Feedbacks" item={<AddFeedback />} />
        <div
          className="w-full cursor-pointer bg-[#00796B] text-white text-sm h-[40px] rounded-lg mt-8 flex items-center justify-center"
          onClick={() =>
            Modal.confirm({
              title: "Are you sure you want to logout?",
              centered: true,
              okText: "Logout",
              onOk: () => logout(navigate),
            })
          }
        >
          Log Out
        </div>
      </div>
    </ScreenLayout>
  );
};

export default Settings;
