import React from "react";
import { Link, useLocation } from "react-router-dom";
import NavLinks, { NavLinksType } from "./NavLinks";

interface NavProps extends NavLinksType {
  key: number;
}

const IconItem = ({ icon, title, route, id, disabled }: NavProps) => {
  const { pathname } = useLocation();

  if (disabled) return null;

  return (
    <Link className={`flex items-center  flex-col text-black`} to={route}>
      <div className={`${pathname === id ? "text-themeBlue" : "text-black"} `}>
        {icon}
      </div>
      <p
        className={`text-sm ${
          pathname === id ? "text-themeBlue" : "text-black"
        } mb-0`}
      >
        {title}
      </p>
    </Link>
  );
};

const Navbar = () => {
  return (
    <div className="fixed max-w-[480px] m-auto z-[999999] bg-white  bottom-4 left-[50%] translate-x-[-50%] px-6 py-2 rounded-3xl shadow-md flex justify-between items-center w-[90%] ">
      {NavLinks?.map(
        ({ icon, title, route, id, disabled }: NavLinksType, key: number) => (
          <IconItem key={key} {...{ icon, title, route, id, disabled }} />
        )
      )}
    </div>
  );
};

export default Navbar;
