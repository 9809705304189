import { message } from "antd";
import moment from "moment";
import React from "react";
import { BiTrash } from "react-icons/bi";
// import { BiTrash } from "react-icons/bi";
import { MessCutType } from "../../../common/types";
import { useMessCutStore } from "../../common/stores";
import { useBaseStore } from "../../common/stores/Base";
import { getDaysDifference } from "../../common/utils";

const CutCard = ({ cut }: { cut: MessCutType }) => {
  const canDelete = (cutFrom: number) => {
    let cutFromDate = parseInt(moment(cutFrom).format("DD"));
    let cutFromMonth = parseInt(moment(cutFrom).format("MM"));
    let cutFromYear = parseInt(moment(cutFrom).format("YY"));
    let todayDate = parseInt(moment().format("DD"));
    let todayMonth = parseInt(moment().format("MM"));
    let todayYear = parseInt(moment().format("YY"));
    // Check for year also, or else Y2K kinda problem will come (Hehe)
    if (todayYear < cutFromYear) return true;
    if (todayMonth < cutFromMonth) return true;
    else if (todayMonth === cutFromMonth && todayDate <= cutFromDate - 2)
      return true;
    return false;
  };

  const [, { handleDeleteMessCut }] = useMessCutStore();
  const [{ user }] = useBaseStore();

  return (
    <div className="shadow-md p-6 my-4 rounded-lg flex flex-row items-center justify-between">
      <div className="flex flex-col items-center justify-center text-center">
        <h2 className="text-4xl m-0 p-0 text-themeBlue">
          {getDaysDifference(cut?.from, cut?.to)}
        </h2>
        <p className="m-0 p-0">Days</p>
      </div>
      <div className="flex flex-col items-center justify-center text-center">
        <time className="font-semibold">
          {moment(cut?.from).format("DD MMM YYYY")}
        </time>
        <p className="m-0 p-0">to</p>
        <time className="font-semibold">
          {moment(cut?.to).format("DD MMM YYYY")}
        </time>
      </div>
      <div
        className="p-2"
        onClick={
          canDelete(cut?.from)
            ? () => handleDeleteMessCut(user, cut)
            : () => message.error("Too late! You can't delete this mess cut")
        }
      >
        <BiTrash className="text-2xl cursor-pointer text-red-500" />
      </div>
    </div>
  );
};

export default CutCard;
