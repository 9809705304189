import { createStore, createHook } from "react-sweet-state";
import { config } from "../../../../common";
import actions from "./actions";
import initialState from "./initialState";

const Store = createStore({
  name: `MessCut_MessedUp_${config.ENV}`,
  initialState,
  actions,
});

export const useMessCutStore = createHook(Store, {
  selector: (state) => state,
});
