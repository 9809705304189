import { message } from "antd";
import { RcFile, UploadChangeParam, UploadFile } from "antd/lib/upload";
import { StoreActionApi } from "react-sweet-state";
import { logger } from "../../../../common";
import { saveUserInfo, saveUserInMess } from "../../../../infrastructure/Auth";
import { Cache } from "../../../../infrastructure/common";
import { uploadStorageFile } from "../../../../infrastructure/Storage";
import { getBase64, resizeFile } from "../../utils";

import initialState from "./initialState";

const actions = {
  setLoading:
    (loading: boolean) =>
    ({ setState }: StoreActionApi<typeof initialState>) => {
      setState({ loading });
    },

  setImage:
    (info: UploadChangeParam<UploadFile>) =>
    ({ setState }: StoreActionApi<typeof initialState>) => {
      setState({ uploadingImage: true });
      // Get this url from response in real world.
      getBase64(info.fileList[0].originFileObj as RcFile, (url) => {
        setState({ uploadingImage: false, previewUrl: url });
      });
      setState({ image: info });
    },

  handleRemoveImage:
    () =>
    ({ setState }: StoreActionApi<typeof initialState>) => {
      setState({ image: null, uploadable: null });
    },

  handleBeforeUploadFile:
    (file: any) =>
    async ({
      getState,
      dispatch,
      setState,
    }: StoreActionApi<typeof initialState>) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
        return true;
      }
      const isLt4M = file.size / 1024 / 1024 < 4;
      const isLt500KB = file.size / 1024 / 1024 < 0.5;
      if (!isLt4M) {
        message.error("Image must smaller than 4MB!");
        return true;
      }
      // compressing file
      try {
        let newFile;
        if (isLt500KB) {
          newFile = { ...{ image: file, uid: file.uid } };
        } else {
          const image = await resizeFile(file);
          newFile = { ...{ image, uid: file.uid } };
        }
        setState({ uploadable: newFile });
        return !(isJpgOrPng && isLt4M);
      } catch (err) {
        logger.error(err, "handleBeforeUploadFile");
      }
    },

  uploadImage:
    (user: any, setUser: any) =>
    async ({
      setState,
      dispatch,
      getState,
    }: StoreActionApi<typeof initialState>) => {
      dispatch(actions.setLoading(true));
      try {
        const { uploadable } = getState();
        const imageUrl: string = await uploadStorageFile(
          uploadable?.uid,
          uploadable?.image,
          Cache.getItem("messno")
        );
        const newUserData = {
          ...user,
          photoURL: imageUrl,
          imageUploaded: true,
          active: true,
        };
        const promises: any[] = [
          saveUserInMess(
            newUserData,
            Cache.getItem("hostel"),
            Cache.getItem("messno")
          ),
          saveUserInfo(newUserData),
        ];
        await Promise.all(promises);
        setUser(newUserData);
      } catch (error) {
        logger.error(error, "uploadImage");
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
};

export default actions;
