import React from "react";
import { MESS_RULES } from "../../common/constants";
const MessRules: React.FC = () => {
  return (
    <div className="pt-5">
      <h2 className="text-center text-xl font-semibold mb-4 text-[#00796B]">MESS RULES</h2>
      <ul>
        {MESS_RULES.map((rule, index) => (
          <li key={index}>{rule}</li>
        ))}
      </ul>
    </div>
  );
};

export default MessRules;
