import { Alert } from "antd";
import React from "react";
import { Cache } from "../../../infrastructure/common";
import { ramadanHostels } from "../../common/constants";
import { useBaseStore } from "../../common/stores/Base";
import { capitalizeFilter } from "../../common/utils/filters";

const Details = ({
  messcuts,
  onPay,
  isSettled,
  totalAmount,
  handleOptForRamadan,
  updateUser,
}: any) => {
  const [{ user }] = useBaseStore();

  const DetailsItem = ({ value, title }: any) => {
    return (
      <div className="flex flex-col items-center justify-center">
        <h2 className="m-0 font-bold text-xl">{value}</h2>
        <h3 className="m-0 text-sm text-[#093f39]">{title}</h3>
      </div>
    );
  };
  return (
    <div className="">
      <h1 className="text-center text-2xl uppercase font-semibold pt-2 mb-1">
        {Cache.getItem("name") ?? "N.A"}
      </h1>
      <p className="m-0 text-center pb-6 text-[#00534a]">
        {capitalizeFilter(Cache.getItem("hostel") ?? "N.A")}
      </p>
      <div className="flex items-center justify-between">
        <DetailsItem
          value={
            Cache.checkItem("messno")
              ? Cache.getItem("messno")?.replace("mn", "")
              : "N.A"
          }
          title="Mess No"
        />
        <DetailsItem
          value={`₹ ${!isSettled ? totalAmount ?? 0 : 0}`}
          title="Bill Pending"
        />
        <DetailsItem value={messcuts ?? 0} title="Mess Cuts" />
      </div>
      <div
        className="w-full cursor-pointer bg-[#00796B] text-white text-sm h-[40px] rounded-lg mt-8 flex items-center justify-center"
        // onClick={onPay}
      >
        Pay Now
      </div>
      {!ramadanHostels.includes(Cache.getItem("hostel")) && (
        <div className="mt-4">
          {user?.ramadanOpt === true && (
            <Alert
              showIcon
              description="Your mess is temporarily paused since you have opted for ramadan mess."
              type="info"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Details;
