import React from "react";
import { Empty } from "antd";
import { MessCutType } from "../../../common/types";
import CutCard from "./CutCard";

const CutDeck = ({
  cuts,
  month,
  hideIfEmpty = false,
}: {
  cuts: MessCutType[];
  month: string;
  hideIfEmpty?: boolean;
}) => {
  if (hideIfEmpty && cuts.length === 0) return <></>;
  return (
    <div className="mt-12">
      <h2 className="uppercase font-semibold text-xl text-[#00796B]">
        Mess Cuts in {month}
      </h2>
      <div className="m-auto my-4">
        {cuts.length === 0 ? (
          <Empty className="!mt-12" description={`No messcuts in ${month}`} />
        ) : (
          cuts.map((cut, key) => <CutCard cut={cut} key={key} />)
        )}
      </div>
    </div>
  );
};

export default CutDeck;
