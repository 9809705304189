export const equalFilter = (
  filter: string | number | boolean,
  value?: string
) => String(value).toLowerCase() === String(filter).toLowerCase();

export const includeFilter = (
  filter: string | number | boolean,
  value?: string
) => String(value).toLowerCase().includes(String(filter).toLowerCase());

export const ellipsisFilter = (len: number, value: string) => {
  const textStr = String(value);
  if (textStr.length > len) return textStr.substring(0, len) + "...";
  return textStr;
};

export const capitalizeFilter = (word: string) =>
  String(word[0]).toUpperCase() + String(word).slice(1);
