import { message } from "antd";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Cache } from "../../../../infrastructure/common";
import { Auth } from "../../../../infrastructure/common/firebase";
import routes from "../../routes";
import { useBaseStore } from "../../stores/Base";
import Loader from "../Loader";
import Navbar from "../Navbar";

const Layout = () => {
  const [authenticating, setAuthenticating] = useState<boolean>(true);
  const [{ activeUser }, { getUserDetails }] = useBaseStore();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!Auth?.currentUser && !activeUser) {
        if (Cache.checkItem("messno") && Cache.checkItem("hostel")) {
          const userFetched = await getUserDetails(
            Cache.getItem("hostel"),
            Cache.getItem("messno")
          );
          if (userFetched) {
            setAuthenticating(false);
            return navigate(routes.HOME);
          }
          message.error("User not found.");
        }
        setAuthenticating(false);
        return navigate(routes.LOGIN);
      } else {
        setAuthenticating(false);
      }
    })();
  }, [activeUser, getUserDetails, navigate]);

  if (authenticating) return <Loader tip="Trying to sign you in..." />;

  return (
    <main>
      {/* <Header /> */}
      <section className="max-w-[480px] m-auto bg-white h-[100%]">
        <Outlet />
      </section>
      <Navbar />
    </main>
  );
};

export default Layout;
