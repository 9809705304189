import moment from "moment";
import { UserType } from "../../../common/types";

export const messCommitteInfo = (hostel: string) => {
  switch (hostel) {
    case "sarovar":
      return [
        {
          name: "Arjun Rajeev",
          role: "Mess Secretary",
          contact: "6282349338",
        },
        {
          name: "Abdulla Sahal",
          role: "Assistant Mess Secretary",
          contact: "8714302531",
        },
      ];

    default:
      return [
        {
          name: "NAME",
          role: "ROLE",
          contact: "XXXXXXXXXX",
        },
      ];
  }
};

//Mess Rules
export const MESS_RULES = [
  "1. All inmates are equal in the mess hall and any type of discrimination will not be entertained in the mess hall (Fine Rs 50).",
  "2. Dressing should be proper inside the mess hall (Sleeveless dresses are not allowed) (Fine Rs 50).",
  "3. Foul language and gestures are strictly prohibited in the mess hall during mess time. (Fine Rs 50 per word).",
  "4. Non-veg inmates are not allowed to take the items kept for veg inmates and vice versa under any circumstances.",
  "5. All inmates should enter their details in the mess committee register both the front page and the inner page entry. (Fine 10 for front page entry and 50 for inner page entry if not filled).",
  "6. All the extras and guests should be entered in the mess register within a week by the inmates themselves. If they fail to do so, entry of each item will be charged an extra of 5 rupees as a fine by the committee.",
  "7. Mess out inmates are not allowed to enter the mess or take food from the mess.",
  "8. Mess out inmates can have food only after buying a coupon (They can never have food as a guest of any other inmates) from the mess committee.",
  "9. Mess reduction must be informed to the committee and it can only be given for at least a day in advance.",
  "10. Mess reduction will be allowed for a minimum of 3 days and the inmate will be charged 30 percent of per day cost. Inmates will not be charged per day for a reduction of 5 or more days.",
  "11. Mess reduction inmates can have food using self-guest during the mess reduction time if the guest is allowed by the committee.",
  "12. Mess reduction is counted for billing if and only if the inmates have entered it in the mess committee register before auditing.",
  "13. Guests will only be allowed in the presence of the inmate (host) and the committee.",
  "14. Spitting in the washbasin inside the mess hall is strictly prohibited.",
  "15. Those who fail to have their late mess food will be fined an amount 3 times the coupon rate.",
  "16. Wastage of food is considered as a punishable offense.",
  "17. Inmates should inform the committee about the mass absence due to special functions and ceremonies in their department to avoid wastage of food.",
  "18. Wash your hands properly before entering the mess hall.",
  "19. Wear a facemask and keep social distance to make yourselves safe and healthy.",
  "20. Do not use used plates or glasses without washing.",
  "21. For mess out inmates, reduction will not be applicable, and half of the per day amount will be charged for each mess-out day.",
  "22. Taking the mess properties like glass, jugs, plates, chairs outside the mess hall will be fined.",
  "23. Extras and guests for the inmates who have an ABLC claim will not come under the claim (they have to pay for extras and guests).",
  "24. A mess inmate can only keep a maximum due of 150 rupees in their mess bill; those who fail will be mess out.",
  "25. Those who are stopping the mess temporarily or permanently have to inform the mess secretary or else you will have to pay the establishment charges for each month.",
  "Mess rules are in effect even after 15 minutes from the closing time.",
];

export const paymentLinks = (
  amount: number,
  hostel: string,
  user?: UserType | null
) => {
  switch (hostel) {
    case "sarovar":
      return {
        upiId: "sarovarmenshostel@sbi",
        upiLink: `upi://pay?pa=sarovarmenshostel@sbi&pn=SecretarysarovarHostel&am=${amount}&cu=INR&tn=Name: ${
          user?.name
        }, Mess-No: ${user?.messno}, Amount: ${amount}, Date: ${moment().format(
          "DD-MM-YYYY"
        )}`,
      };
    default:
      return {
        upiId: "sarovarmenshostel@sbi",
        upiLink: `upi://pay?pa=sarovarmenshostel@sbi&pn=SecretarysarovarHostel&am=${amount}&cu=INR&tn=Name: ${
          user?.name
        }, Mess-No: ${user?.messno}, Amount: ${amount}, Date: ${moment().format(
          "DD-MM-YYYY"
        )}`,
      };
  }
};

export const messCutsDisabledHostels = ["sahara-ramadan"];
export const ramadanHostels = ["sahara-ramadan"];
